// import jwtDecode from 'jwt-decode';

export const isValidEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const validURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export const interpolate = function(text, params) {
    const names = Object.keys(params);
    const values = Object.values(params);
    return new Function(...names, `return \`${text}\`;`)(...values);
}

export const TOKEN = 'ksniq3sync'

export const isAuthenticated = () => {
    const token = localStorage.getItem(TOKEN);
    // const decoded = token && jwtDecode(token);
    // let isTokenExpired = false;
    // if (decoded && decoded.exp) {
    //     const dataNow = new Date();
    //     const tokenExpDate = new Date(decoded.exp * 1000);
    //     if (tokenExpDate.getTime() - dataNow.getTime() < 0) {
    //         isTokenExpired = true;
    //     }
    // }
    return Boolean(token);
}
