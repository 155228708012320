var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"bg-dark-blue bg-opacity-100"},[_c('div',{staticClass:"max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-between md:h-16"},[_c('div',{staticClass:"flex flex-col md:flex-row md:items-center"},[_c('router-link',{staticClass:"flex-shrink-0 my-5 md:my-auto",attrs:{"custom":"","to":{ name: 'List Campaigns' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"h-8 w-8",attrs:{"src":require("../assets/rocket.svg"),"alt":"Workflow"}})])]}}])}),_c('div',{staticClass:"flex-shrink-0"}),_c('div',{staticClass:"md:block"},[_c('div',{staticClass:"md:ml-10 flex flex-col md:flex-row md:items-baseline md:space-x-4"},[_c('router-link',{staticClass:"text-white px-4 py-2 rounded-md text-sm font-medium",attrs:{"custom":"","to":{ name: 'List Campaigns' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{class:[isExactActive && 'bg-white bg-opacity-10 font-bold'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])]}}])}),_c('router-link',{staticClass:"text-white px-4 py-2 rounded-md text-sm font-medium",attrs:{"custom":"","to":{ name: 'New Campaign' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{class:[isExactActive && 'bg-white bg-opacity-10 font-bold'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])]}}])}),_c('router-link',{staticClass:"text-white px-4 py-2 rounded-md text-sm font-medium",attrs:{"custom":"","to":{ name: 'Advertising with TrafficShip' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{class:[isExactActive && 'bg-white bg-opacity-10 font-bold'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])]}}])})],1)])],1),_c('div',{staticClass:"my-5 md:my-auto"},[_c('div',{staticClass:"md:ml-4 flex items-center md:ml-6 w-full"},[_c('button',{staticClass:"bg-white font-medium px-8 py-2 rounded-md text-sm uppercase dark-blue w-full md:w-auto",attrs:{"role":"menuitem"},on:{"click":_vm.onLogout}},[_vm._v(" Log Out ")])])])])])]),_c('header',{staticClass:"bg-white shadow"},[_c('div',{staticClass:"max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"},[_c('h1',{staticClass:"text-3xl font-extrabold text-gray-900"},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")])])]),_c('main',{staticClass:"main-viewport"},[_c('div',{staticClass:"max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"},[_c('router-view')],1)]),_c('footer',{staticClass:"footer bg-white relative"},[_c('div',{staticClass:"container mx-auto px-6 py-4"},[_c('div',{staticClass:"mb-4 flex flex-col md:flex-row md:items-baseline space-y-2 md:space-y-0 md:space-x-4 justify-center text-center"},[_c('router-link',{staticClass:"text-sm underline",attrs:{"custom":"","to":{ name: 'List Campaigns' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])]}}])}),_c('router-link',{staticClass:"text-sm underline",attrs:{"custom":"","to":{ name: 'New Campaign' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])]}}])}),_c('router-link',{staticClass:"text-sm underline",attrs:{"custom":"","to":{ name: 'Advertising with TrafficShip' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])]}}])}),_c('router-link',{staticClass:"text-sm underline",attrs:{"custom":"","to":{ name: 'Terms of Service' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])]}}])}),_c('router-link',{staticClass:"text-sm underline",attrs:{"custom":"","to":{ name: 'Cancellation Policy' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])]}}])})],1),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"max-w-md mx-auto my-4 text-center text-sm"},[_vm._v(" If you are not happy with the zone you purchased or want to change zones, please contact us at "),_c('a',{staticClass:"underline",attrs:{"href":"mailto:sales@trafficship.com","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("sales@trafficship.com")])])}]

export { render, staticRenderFns }