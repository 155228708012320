<template>
  <div class="text-lg text-gray-600 p-4 md:p-8 bg-white rounded-lg">
    <div class="item">
      <h2>1. Acceptance of Terms</h2>
      <p><img src="../assets/company_name.png" style="height:1.1em; display: inline; vertical-align: baseline;margin-bottom: -2px;margin-right: 2px;">("TrafficShip") registered at <img src="../assets/address.png" style="display: inline; height: 0.95em;vertical-align: bottom;margin-bottom: 4px;margin-right: 2px;"> welcomes you to its online Service (as defined in Section 2 below). By using the Service you are agreeing to the following Terms of Service (“TOS”) whether or not you are a registered customer of TrafficShip or create an account on the Service.</p><p>The TOS, and your agreement to it, incorporates the privacy policy, posting guidelines, copyright policy, reporting policies referenced in this document. Your agreement to all these terms as they appear, or may be updated from time to time, is an absolute condition of your use of the Service.</p><p>You understand the service provided by TrafficShip.com contains content on this site is user generated. TrafficShip.com provides a
      forum for informational and entertainment purposes.</p><p>You understand that the Service provided by TrafficShip contains content that is user generated. TrafficShip provides a forum for informational and entertainment purposes.</p><p>You agree that you are the age of majority in the jurisdiction in which you reside and where the content provided through the Service is accessed, and that you are legally permitted to enter into this agreement and are legally allowed to possess adult and explicit material.</p><p>You agree to comply with all applicable laws of the jurisdiction in which you reside. In the interest of providing a safe and enjoyable service, you agree to report activity to TrafficShip that you know to be illegal or that would violate these TOS.
      </p><p>We reserve the right in our sole discretion to modify the TOS at any time by providing you with notice of the change. Notice includes but is not restricted to posting a link to the new TOS, posting a new TOS at TrafficShip.com, or sending you a link to the amended TOS via contact information you have provided to us. Such change will be effective with respect to your use of the Service after we have given notice of the change. We also reserve the right to modify the price of the Service. Please review the TOS periodically to see whether they have changed. If you do not agree to the changed TOS, do not use the Service. Any use of the Service by you after we provide notice to you constitutes your acceptance of those modifications.</p>
      <p>When using any TrafficShip Services, you shall be subject to any posted policies, guidelines or rules applicable to such Services. All such policies, guidelines and rules are hereby incorporated by reference into the TOS.</p>
      <p>IF YOU DO NOT AGREE TO THE CURRENT TERMS OF SERVICE, PLEASE DO NOT USE THIS SERVICE, SINCE YOUR USE OF IT CONSTITUTES ACCEPTANCE OF ALL OF THE CURRENT TERMS OF SERVICE.</p>
    </div>
    <div class="item">
      <h2>2. Description of Service</h2>
      <p>The services provided by TrafficShip include, but are not limited to, online classified advertisements, live chat, forum, social networking, text messages and email services (collectively, the “Service”). Any new features, platforms, functionalities, services and enhancements offered by TrafficShip will be considered part of the Service and subject to the TOS.</p><p>All users must abide by the TOS. If a user fails to follow any of the guidelines and/or rules of behavior applicable to the Service, TrafficShip can delete content posted by a user and/or discontinue their ability to use the Service. </p><p>You are totally responsible for any activity that takes place under your username and password. If you become aware of any unauthorized use of your username or password it is your responsibility to notify TrafficShip immediately at <a href="mailto:info@trafficship.com" title="" target="_blank" rel="noopener noreferrer">info@trafficship.com</a>. It is your responsibility to maintain the confidentiality of your password and username.</p>

      <p>If you choose to receive mobile notifications and our sms bump feature concerning any ad, you agree that we may send automated text messages to your mobile phone or device. If you do not wish to receive such text messages, you should not accept the mobile notifications option. We will not send you any marketing or advertising material by text messages. Message and data rates may apply for mobile notifications. You are totally responsible for the actions that are triggered on TrafficShip by the use of your mobile device.</p>

      <p>You understand that you may receive business-related communications from TrafficShip such as Service announcements and account administrative notices and you agree that these communications are not “commercial electronic messages” within the meaning of Hungarian Anti-Spam Legislation, and thus you agree to receive them and you will not be able to opt out of receiving such communications.</p>

      <p>As a user of the Service you also understand and agree that the Service will include classified advertisements. You agree that such advertisements are not “commercial electronic messages” and thus you agree to receive them and you will not be able to opt out of receiving such communications. You understand and agree that your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Service, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and the advertiser. You agree that TrafficShip shall not be responsible or liable for any loss or damage incurred as the result of such dealings or as the result of the presence of such advertisers on the Service.</p>

      <p>You understand that you are responsible for obtaining access to the Service and that access may involve third party fees. You are totally responsible for obtaining such access and paying those fees.</p>

      <p>Unless explicitly stated otherwise, any new features that augment or enhance the Service, including the release of new TrafficShip properties, shall be considered part of the Service and subject to the then current TOS.</p>
      <p><b>You must have reached the age of majority in the jurisdiction in which you reside, to access and use the Service. If you are under the age of majority, do not use this Service for any purpose.</b></p>
    </div>
    <div class="item">
      <h2>3. Rules of Behavior </h2>
      <p><span>• You are solely responsible for your own communications and User Content (defined below) that you post on the Service. You agree to only use the Service to post and receive communications and User Content that are legal.</span></p>

      <p><span>• You must not abuse, defame, harass, stalk, threaten, intimidate, or otherwise violate the legal rights of others.</span></p>

      <p><span>• You must not upload, post or link to any material that is defamatory, infringing, libelous, obscene, racially or ethnically hateful, objectionable, or unlawful or that promotes or provides instructional information about illegal activities.</span></p>

      <p><span>• You must respect the privacy of other users, and use the personal information of others solely for the purpose for which it was shared with you.</span></p>

      <p><span>• You must not upload or post any User Content that infringes any patent, trademark, copyright, trade secret or other intellectual property or other legal right of a third party without prior written permissions from the third party, which permissions you agree to disclose to us on our request. You must not download any material that you know or reasonably should know cannot be distributed legally.</span></p>

      <p><span>• You agree to not impersonate another person or entity.</span></p>

      <p><span>• You must not use the  Service for any illegal or unauthorized purpose. This includes, but is not limited to, spamming, impersonation, extortion or fraud.</span></p>

      <p><span>• You must abide by all applicable Federal, Provincial, Territorial and local laws, including, without imitation, any laws regarding the export of data or software, patent, trademark, trade secret, copyright, or other intellectual property, legal rights (including the rights of publicity and privacy of others) and User Content must not contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use or any TrafficShip policies or guidelines, including our privacy policy.
        </span></p>

      <p><span>• You cannot modify or delete any User Content or communications posted by TrafficShip or other users.</span></p>

      <p><span>• You must not transmit any viruses, worms, defects, or any items and/or code of a destructive nature.</span></p>

      <p><span>• You must not transmit or allow access or distribution of any spiders, robots or other information-gathering computer programs or routines or their equivalents on or in the Service.</span></p>

      <p><span>• The contents associated with the Service, including the User Content, may not be modified or altered in any respect, merged with other data or published in any form, in whole or in part. The prohibited uses include "screen scraping", "database scraping" and any other automated or manual activity intended to collect, store, reorganize or manipulate data on the pages produced by, or displayed in associated with the Service.</span></p>

      <p><span>• You must not interfere or disrupt the Service or servers or networks connected with the Service or disobey any requirements, procedures, policies or regulations of networks connected to the Service.</span></p>

      <p><span>• You must not retrieve, store or collect personal information about other users for any unauthorized purpose.</span></p>

      <p><span>• You cannot use the TrafficShip name or trademark, which you acknowledge here to be valid, subsisting and enforceable without impediment.</span></p>

      <p><span>WHILE TRAFFICSHIP EXPLICITLY PROHIBITS THE ABOVE CONDUCT YOU ACKNOWLEDGE AND AGREE THAT YOU MAY BE EXPOSED TO SUCH CONDUCT AND CONTENT AND THAT YOU USE THE SERVICE ENTIRELY AT YOUR OWN RISK, AND THAT TRAFFICSHIP SHALL HAVE NO LIABILITY OF ANY KIND FOR SUCH CONDUCT.</span></p>

      <p><span>YOU UNDERSTAND AND AGREE THAT IF YOU VIOLATE ANY OF THE RULES OF BEHAVIOR, TRAFFICSHIP CAN TERMINATE YOUR USE OF THE SERVICE IMMEDIATELY WITHOUT PRIOR NOTICE WITHOUT ANY RIGHT OF REFUND, SET-OFF OR A HEARING.</span></p>
    </div>
    <div class="item">
      <h2>4. Content   </h2>
      <p>As a registered user, you and all other registered users will be able to upload and post a great variety of content, material, and information on the Service,  including but not limited to text, audio, photographs, graphics, data, images, video, email addresses, links, screen names and other materials (“User Content”). This means that you have the sole responsibility, not TrafficShip, for all User Content that you upload, post, email, transmit or otherwise make available through the Service.  It is your responsibility to ensure that any User Content you share through the Service complies with all applicable laws and  with these TOS, including the Rules of Behavior in Section 3, above. You submit it--you’re responsible for it.</p>

      <p>By using the Service you agree not to post any obscene or lewd and lascivious graphics or photographs which depict genitalia, actual or stimulated sexual acts or nude images. User Content posted cannot advertise or solicit directly or in any "coded", fashion ANY illegal service, including an offer to provide sexual services  for money or other consideration. User Content posted cannot exploit minors in any way. User Content posted cannot in any way constitute or assist in human trafficking.</p>

      <p>Notwithstanding the foregoing, TrafficShip shall have no liability of any kind with respect to any User Content posted by you or other users of the Service. You agree that you must assess and bear all risks associated with the creation or use of any User Content. In this regard, you may not rely on any User Content created by other TrafficShip members or otherwise created, distributed and displayed on any part of the Service. TrafficShip does not control or monitor the User Content posted via the Service and, as such, does not guarantee the accuracy, integrity or quality of such User Content.</p>

      <p>The User Content created by you shall be owned by you, or you must have been granted prior permission to use it. TrafficShip does not own any of the User Content you post. By placing User Content on the Service you are providing TrafficShip with a worldwide, royalty-free, unlimited, non-exclusive license, for as long as the User Content is posted on the Service, to publicly display, modify, reproduce, make available, and distribute your User Content on the Service or for editorial and promotional purposes of the Service. TrafficShip reserves the right to syndicate or promote this User Content in connection with the promotion of the Service and other services offered by TrafficShip.</p>

      <p>TrafficShip is not responsible for enforcing any rights you claim in any User Content, which shall be your responsibility entirely, and you agree to hold harmless and indemnify TrafficShip with respect to any claim you have that other users are reproducing or using your User Content, as well as for claims by other users that you are reproducing or using their User Content without permission. TrafficShip will not arbitrate, mediate or resolve any intellectual property or other disputes between users, and has no responsibility for doing same.</p>

      <p>You acknowledge that TrafficShip and its designees shall have the right, but not the obligation, in its sole discretion, to pre-screen, refuse, move, or delete, any User Content that violates the TOS or is otherwise objectionable.
        TrafficShip may verify the authenticity and appropriateness of certain User Content as described in detail in the TOS or the posting guidelines. TrafficShip offers this Service but does not warrant or condition that it will be effective in ensuring that User Content is authentic or appropriate.</p>

      <p>As set out in greater detail in our Privacy Policy, TrafficShip may preserve and store your account information and User Content if it believes, in good faith, that such preservation is necessary to comply with legal processes, respond to claims that the User Content violates the rights of third parties, or to protect the rights, property or safety of TrafficShip, its users and the broader public.</p>

      <p>TrafficShip provides services that enable authorized users to communicate or otherwise share information or content with other users or persons regarding the provision of goods or services. Insofar as TrafficShip provides such service, and you elect to use the service, you AGREE to NOT post, publish, display, disseminate, or otherwise communicate any defamatory, inaccurate, abusive, threatening, offensive, obscene, fraudulent or illegal User Content or any User Content which would violate or infringe the copyright, trademark, rights of publicity, privacy rights or other rights of any person.</p>

      <p>You acknowledge that posting or otherwise communicating User Content that violates the law of any country, or political subdivision thereof, is strictly prohibited by TrafficShip. Disseminating content in violation of any laws, regulations or these TOS shall constitute a material breach of the TOS entitling TrafficShip, without notice and without any liability for damages or reimbursement to you, to immediately terminate your rights to access the Service.</p>
    </div>
    <div class="item">
      <h2>5. Links</h2>
      <p>The Service may provide, or third parties including TrafficShip users, may provide links to other websites or online resources. TrafficShip has no control over these sites and their content and therefore you acknowledge that TrafficShip is not responsible for the availability of such links, resources and content, and does not endorse and is not responsible or liable for any content, advertising, products, or other materials made available on or from these linked websites. You also acknowledge and agree that TrafficShip is not responsible or liable, directly or indirectly, for any damage or loss caused by or alleged to have been caused by or in relation to the use of content, goods or services offered through these links or any failures and/or disruption to your computer system that results from your use of any such links, or for any intellectual property or other third party claims relating to your posting or using such links.</p>

      <p>YOU AGREE THAT TRAFFICSHIP CAN REQUEST YOU TO DISABLE ANY LINK YOU HAVE POSTED AND HAS THE RIGHT TO DISABLE THE LINK WITHOUT NOTICE TO YOU.</p>
    </div>
    <div class="item">
      <h2>6. Indemnity</h2>
      <p>To the maximum extent permitted by applicable law, you agree to defend, indemnify and hold harmless TrafficShip, its subsidies, affiliates, officers, agents, and other partners and employees, from any and all losses, liabilities, claims, demands, damages, judgments, awards, losses, costs, expenses, or fees (including legal fees and disbursements), arising out of or relating to your breach of the TOS or your use of the Service, including but not limited to, any User Content you submit using the Service, third party websites or online resources, any use of TrafficShip’s Service other than as expressly authorized in the TOS.</p>
    </div>
    <div class="item">
      <h2>7. Financial Policies</h2>
      <p>You agree not to, without the express prior written consent of TrafficShip: (a) reproduce, duplicate, copy, sell, resell, or exploit for any commercial purposes, any portion of the Service or User Content you do not own, (b) use the Service or User Content you do not own, or (c) access to the Service or User Content you do not own.</p>

      <p>TrafficShip retains the right to adjust, modify or supplement rates charged for Services. Such changes may be posted in these TOS and the pricing sections, without prior notice. </p>

      <p>TrafficShip shall not issue cash refunds and any billing errors reported will be directed to our billing service provider for resolution. TrafficShip may, in its sole discretion, grant credits in the form of gift cards to resolve customer service issues.&nbsp;</p><p>&nbsp;Purchases made on the site via debit or credit card will appear as TrafficShip or as TrafficShip.com in your bank statement.</p>

    </div>
    <div class="item">
      <h2>8. Modification and Termination of the Service   </h2>
      <p>TrafficShip reserves the right at any time and from time to time to modify, suspend or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that TrafficShip will not be liable to you or any third party for any modification, suspension or discontinuance of the Service.</p>
    </div>
    <div class="item">
      <h2>9. TrafficShip Privacy Policy   </h2>
      <p>Personal information and certain other information is subject to our Privacy Policy. As a condition of using the Service you agree to the terms of the Privacy Policy, as it may be changed from time to time. Our Privacy Policy, which is incorporated here by reference, is located here.</p>
    </div>
    <div class="item">
      <h2>10. Registration</h2>
      <p>In order to use certain aspects of the Service you are required to register. We refer to registered users as “Members”. Members are subject to the following specific terms in addition to all of the other terms in this Agreement:</p>

      <p>•
        In consideration of your use of the Service, you represent that you are of legal age to form a binding contract, of the age of majority in your jurisdiction, and are not a person barred from receiving Services under applicable laws of the jurisdiction in which you reside. </p>

      <p>•
        You agree to provide true, accurate, current and complete information as required on the Service’s Registration Form. If you provide any information that is untrue, incomplete, not current or inaccurate, TrafficShip has the right to suspend or terminate your account and refuse your current or future use of the Service (or any portion thereof.)</p>

      <p>•
        You agree that TrafficShip may, under certain circumstances and without prior notice, immediately suspend your TrafficShip account, any associated username and/or access to the Service. Cause for such termination shall include, but not be limited to: i) breaches or violations of the TOS or other policies, guidelines or rules, ii) extended periods of inactivity, iii) your engagement in fraudulent or illegal activity, iv) unexpected technical or security issues, and v) requests by law enforcement or other government agencies. You also agree that any termination is in TrafficShip’s sole discretion and that TrafficShip will not be liable to you or any third party for any termination of your account, password, username, deletion of User Content you posted on the Service or access to the Service.</p>

      <p>•
        You agree that your account is non-transferable and any rights to your account, password, username, terminate upon your death.
        </p>

      <p>•
        You are responsible for any activities that take place under your username and password. If you become aware of any unauthorized use of your password or account, or any other breach of security, please contact TrafficShip immediately. It is up to you to maintain the confidentiality of your password and account. TrafficShip is not responsible or liable for any loss or damage arising from your failure to comply with the provisions of these TOS.</p>
    </div>
    <div class="item">
      <h2>11. Practices regarding Use and Storage</h2>
      <p>You acknowledge that TrafficShip may establish general practices and limits regarding use of the Service including without limitation the maximum number of email messages which may be sent or received from an account of the Service, the maximum size of any email message that may be sent from or received by an account of the Service, the maximum amount of disk space that will be allotted on TrafficShip’s servers on your behalf, and the maximum number of times and duration for which you may access the Service in a given time. You agree that TrafficShip has no responsibility or liability for the deletion or failure to store or transmit any messages or communications or other User Content maintained or transmitted by the Service. You acknowledge and agree that TrafficShip reserves the right to log off accounts and deactivate usernames and accounts that are inactive for an extended period of time. You further acknowledge that TrafficShip has the right to modify these practices and limits from time to time.</p>
    </div>
    <div class="item">
      <h2>12. TrafficShip Proprietary Rights</h2>
      <p>You acknowledge and agree that the Service and any necessary software used in connection with the Service (“Software”) contain proprietary and confidential information that is the property of TrafficShip and its licensors and is protected by applicable intellectual property and other laws. You further acknowledge and agree that content contained in advertisements or information presented to you through the Service is protected by copyright, trademarks, patents or other proprietary rights and laws. Except as expressly authorized by TrafficShip or advertisers on the Service, you agree not to modify, rent, lease, loan, sell, make available, distribute or create derivative works based on the Service or Software, in whole or in part. You also acknowledge TrafficShip’s exclusive rights in the TrafficShip trademark. </p>
    </div>
    <div class="item">
      <h2>13. Disclaimer of Warranties and Conditions</h2>
      <p>The use of the Service is at your sole risk. The Service is provided on an “as is” and “as available” basis. TrafficShip EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY. MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>

      <p>TrafficShip MAKES NO WARRANTY THAT 1) THE SERVICE WILL MEET YOUR REQUIREMENTS., (2) THE SERVICE WILL BE UNINTERRUPTED, TIMELY SECURE, OR ERROR-FREE, (3) THE RESULTS OF USING THE SERVICE WILL BE ACCURATE OR RELIABLE, (4) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED THROUGH YOUR USE OF THE SERVICE WILL MEET YOUR EXPECTATIONS, OR (5) THAT ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.</p>

      <p>No advice or information, whether oral or written, obtained by you from TrafficShip or through or from the Service shall create a warranty not expressly stated in the TOS.</p>
    </div>
    <div class="item">
      <h2>14. Limitation of Liability</h2>
      <p>Excepts where such exclusions are prohibited by law, you expressly understand and agree that TrafficShip and, its subsidiaries, affiliates, officers, agents, and other partners and employees will not be liable for negligence, gross negligence, negligent misrepresentation, fundamental breach, damages of any kind, under any legal theory, including any direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, or other intangible losses, and whether caused by tort (including negligence), breach of contract, breach of privacy or otherwise, even if the party was allegedly advised or had reason to know, arising out of or in connection with your use, or the inability to use, or reliance on, the Service, unauthorized access to or alteration or alteration of your transmissions or data, statements or conduct of any third party including advertisers on the Service, the cost of procurement of substitute goods and Services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into though or form the Service or any other matter relating to the Service, and in no event will TrafficShip be liable for any amount of money over One Hundred Dollars ($100.00), which you acknowledge to be a fair and reasonable sum.</p>
    </div>
    <div class="item">
      <h2>15. Disputes</h2>
      <p>The TOS will be governed by the laws of the Province of Ontario, and the federal laws of Hungary applicable therein without regard to conflict of law provisions. Except to the extent restricted by applicable law, if there is any dispute or controversy between (1) you and (2) TrafficShip or any of our group companies, affiliates, employees, agents, service providers, or professional advisors (the “TrafficShip Parties”) including any dispute or controversy arising out of or relating to the TOS or the Services, or in respect of any legal relationship associated with or derived from the TOS, including the validity, existence, breach, termination, construction or application, or the rights, duties or obligations of you or the TrafficShip Parties, (each, a "Dispute"), the disputing person will serve notice on the other person and each of you and the applicable TrafficShip Parties must use good faith efforts to resolve the Dispute informally.</p>

      <p>Except to the extent restricted by applicable law, if the Dispute is not resolved after twenty (20) business days of a person serving notice on the other party that there is a Dispute, you and us agree that the Dispute will be finally resolved by confidential arbitration before a single arbitrator in accordance with the Arbitration Rules of the ADR Institute of Hungary, Inc. The seat of the arbitration will be Toronto, Ontario or wherever convenient or necessary acting reasonably. There will be no appeals of any kind. The language of the arbitration will be English unless otherwise required by applicable law or agreed to by you and us.</p>

      <p>Except to the extent restricted by applicable law, you agree that you and the TrafficShip Parties will resolve any Dispute on an individual basis. Any claim you may have must be brought individually, in your individual capacity and not as a representative plaintiff or class member, and you will not join such claim with claims of any other person or entity, or bring, join or participate in a class action lawsuit, collective or representative proceeding of any kind (existing or future) against any of the TrafficShip Parties.</p>

      <p>Nothing in this Section will prohibit us from seeking interim measures from a court, including preliminary or injunctive relief of breach of you of any intellectual property or privacy rights.</p>


    </div>
    <div class="item">
      <h2>16. Copyright and Trademark Policy</h2>
      <p>Trademarks, logos, and copyrighted works (hereinafter, “Intellectual Property”) appearing in this site are the property of TrafficShip or the party that provided the Intellectual Property to TrafficShip.</p>

      <p>TrafficShip and any party that provides Intellectual Property to TrafficShip retain all rights with respect to any of their respective Intellectual Property appearing on the Service. All contents of TrafficShip’s websites are: Copyright © 2021 TrafficShip Inc. All rights reserved.</p>

      <p>We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement. If you believe any materials accessible on or from the Service infringe your copyright, you may request that we send a notice to the alleged infringer by submitting written notification to our Copyright Agent (designated below). The written notice (the "Notice") must include the following:</p>

      <p>• Your name and address.</p>

      <p>• Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Service, a representative list of such works.</p>

      <p>• A statement describing your interest or right with respect to the copyright in the work or other subject-matter.</p>

      <p>• Identification of the location of material you believe to be infringing in a sufficiently precise manner to allow us to locate that material (e.g., a link to the infringing material).</p>

      <p>• Description of the infringement that is claimed.</p>

      <p>• A statement specifying the date and time of the alleged infringement.</p>

      <p>• Any other information that may be prescribed by regulation that we may advise you of from time-to-time.</p>

      <p>The Notice may not contain any of the following:</p>

      <p>• An offer to settle the claimed infringement.</p>

      <p>• A request or demand relating to the claimed infringement for payment or for personal information.</p>

      <p>• A reference, including by way of hyperlink, to such an offer, request or demand.</p>

      <p>• Any other information that may be prescribed by regulation that we may advise you of from time-to-time.</p>

      <p>(the "Prohibited Content").</p>

      <p>A Notice containing Prohibited Content will not be considered a valid Notice under this TOS.</p>

      <p>TrafficShip’s Copyright Agent for notice of claims of copyright infringement on the Service can be reached as follows:</p>

      <p>Attn: Copyright Agent</p>

      <p>e-mail: <a href="mailto:info@trafficship.com" title="" target="_blank" rel="noopener noreferrer">info@trafficship.com</a></p>

      <p>Please be aware that if you knowingly materially misrepresent that material or activity on the Service is infringing your copyright, you may be held liable for damages (including costs and [all/reasonable] legal fees, disbursements and charges).</p>

      <p>If we receive a Notice in the prescribed form, we will, as soon as feasible, forward the Notice electronically to the person to whom the electronic location identified by the location data in the Notice belongs and inform you of our doing so. If, for some reason, it is not possible for us to forward the Notice to such person, we will confirm with you the reasons therefor.</p>

      <p>We will retain records of Notices in compliance with our obligations under applicable laws and regulations.</p>

      <p>Without limiting any other rights or remedies we may be entitled to under the TOS or otherwise at law, TrafficShip reserves the right to remove any material or disable or terminate our users accounts or take such other actions deemed necessary or appropriate in the circumstances in our sole discretion.</p>

    </div>
    <div class="item">
      <h2>17. General Information</h2>
      <p>This Agreement constitutes the entire agreement between you and TrafficShip and governs your use of the Service. You may also be subject to additional terms and conditions, posted policies (including but not limited to the Privacy Policy), guidelines, or rules that may apply when you use or purchase certain elements of the Service, Affiliate or advertiser Services, third-party content or third-party software.</p>

      <p>The failure of TrafficShip to exercise or enforce any right or provision of this TOS shall not operate as a waiver of such right or provision. If any provision of the TOS is found by a court of competent jurisdiction to be invalid, the parties’ intentions as reflected in the provision, and the other provisions of the TOS remain in full force and effect.</p>

      <p>The section titles in the Agreement are for convenience only and have no legal or contractual effect.</p>

      <p>Nothing contained in the TOS shall be construed to constitute either party as a partner, employee or agent of the other party, nor shall either party hold itself out as such. Each party has no right or authority to incur, assume or create, in writing or otherwise, any warranty, liability or other obligation of any kind, express or implied, in the name of or on behalf of the other party, it being intended by both parties that each shall remain independent contractors responsible for its own actions.</p>

      <p>You agree that regardless of any statute or law to the contrary, where permitted, any claim or cause of action arising out of or related to use of the Service or the TOS must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>

      <p>Please report any violations of the TOS <a href="mailto:info@trafficship.com" title="" target="_blank" rel="noopener noreferrer">here</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tos"
}
</script>

<style scoped>
  h2 {
    margin: 0 0 16px;
    font-weight: 700;
    font-size: 18px;
  }

  p {
    margin: 0 0 8px;
  }

  .item {
    margin: 0 0 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #eee;

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: 0;
    }

    h2, p:first-of-type {
      text-indent: 32px;
    }
  }

  a {
    text-decoration: underline;
    color: #3a86f9;
  }

  b {
    font-weight: 700;
  }
</style>
