<template>
  <div id="app">
    <router-view />
    <ErrorBlock v-if="isOutdated" :is-warning="true" >
      <span>
        <span>{{isOutdated}}</span>
        <button @click="onReloadClick" class="inline-block mt-1 bg-white font-medium px-3 py-0 rounded-md text-sm uppercase dark-blue" type="button">Reload</button>
      </span>
    </ErrorBlock>
  </div>
</template>

<script>
import ErrorBlock from "@/components/common/ErrorBlock";
export default {
  name: 'App',
  components: {
    ErrorBlock,
  },
  methods: {
    onReloadClick() {
      location.reload();
    },
  },
  computed: {
    isOutdated() {
      return this.$store.state.isOutdated;
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
body {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  min-height: 100vh;
  color: #333;
  background: #f3f4f6;

  &.home {
    background: radial-gradient(circle at left bottom, #0041A8, rgba(0, 30, 77, 0) 60%) no-repeat left bottom #001e4d;
    background-size: 960px 800px;
    color: #fff;
    text-align: center;

    @media (max-width: 1280px) {
      background-size: 100vw 100vw;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url("./assets/rocket@3x.png") no-repeat 46vw 130px;
      background-size: 640px 640px;
      pointer-events: none;
      z-index: -1;
      mix-blend-mode: lighten;

      @media (max-width: 1280px) {
        background-size: 50vw 50vw;
      }

      @media (max-width: 768px) {
        background-size: 100vw 100vw;
        background-position: 0 40vh;
      }
    }
  }
  .font-medium {
    font-weight: 600;
  }
}

[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  appearance: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

:disabled {
  opacity: 0.7;
  cursor: default;
  pointer-events: none;
}

.modal-open {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
