var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"flex-grow"},[_c('div',{staticClass:"card-footer text-muted mb-3 mt-3 font-bold",class:{
      'text-green-800': _vm.message === 'Upload completed.',
      'text-red-600': _vm.message === 'Oops! Something went wrong.',
    },domProps:{"innerHTML":_vm._s(_vm.message)}})]),_c('div',{staticClass:"bg-gray-100 flex items-center justify-center relative cursor-pointer max-w-full md:max-w-none",style:(("width: " + _vm.width + "px; height: " + _vm.height + "px")),attrs:{"id":("pick-avatar-" + _vm.type)}},[(!_vm.url)?_c('span',{staticClass:"absolute font-bold text-4xl text-gray-300"},[_vm._v(_vm._s((_vm.width + "X" + _vm.height)))]):_vm._e(),(_vm.url)?_c('img',{staticClass:"object-contain max-w-none w-full h-full",attrs:{"src":_vm.url}}):_vm._e()]),(!_vm.disabled)?_c('avatar-cropper',{attrs:{"trigger":("#pick-avatar-" + _vm.type),"upload-form-name":"image","upload-url":_vm.endpoint,"upload-form-data":{zone_id: _vm.zoneId, type: _vm.type},"labels":{submit: 'Submit', cancel: 'Cancel'},"mimes":"image/png, image/jpeg","output-mime":"image/jpeg","cropper-options":{
        aspectRatio: _vm.width / _vm.height,
        autoCropArea: 1,
        movable: false,
        scalable: false,
        zoomable: false,
        zoomOnTouch: false,
        zoomOnWheel: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        dragMode: 'none',
        rotatable: true,
        checkOrientation: true,
      },"output-options":{width: _vm.width, height: _vm.height},"id":("input-avatar-" + _vm.type),"upload-headers":_vm.getAuthHeader()},on:{"uploading":_vm.handleUploading,"uploaded":_vm.handleUploaded,"completed":_vm.handleCompleted,"error":_vm.handlerError}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }