<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <ErrorBlock v-if="error">
          <span>{{error}}</span>
        </ErrorBlock>
        <div class="rounded-t-xl overflow-hidden p-10" v-if="loading">
          <div class="flex justify-around">
            <span class="inline-flex rounded-md shadow-sm">
              <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Loading
              </button>
            </span>
          </div>
        </div>
        <div class="rounded-t-xl overflow-hidden p-10" v-if="!(list && list.length) && !loading">
          <div class="flex justify-around text-center">
            <span class="font-bold inline-flex rounded-md text-3xl text-gray-400">
              There are no active campaigns yet
            </span>
          </div>
        </div>
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" v-if="list && list.length">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Zone
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                CPC
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Clicks Purchased
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total Price Paid
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                status
              </th>
              <th scope="col" class="relative px-6 py-3">
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="item in list" :key="item.id">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">
                  {{item.zone.website}} - {{item.zone.name}}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{(item.zone.price_per_click).toLocaleString('en-GB', {style: 'currency', currency: 'EUR'})}}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{item.clicks_purchased}}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{(item.paid_amount || 0).toLocaleString('en-GB', {style: 'currency', currency: 'EUR'})}}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                  :class="{
                    'bg-green-100 text-green-800': item.status === 'active',
                    'bg-yellow-100 text-yellow-800': item.status === 'completed',
                    'bg-gray-100 text-gray-800': item.status === 'canceled',
                  }"
                >
                  {{item.status.toUpperCase()}}
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button v-if="['rebilled', 'active'].includes(item.status)" :disabled="loading" type="button" @click="() => handleCancelClick(item.id)" class="text-indigo-600 hover:text-indigo-900">Cancel</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
import ErrorBlock from "@/components/common/ErrorBlock";
import get from "lodash.get";
import dayjs from "dayjs";
export default {
  name: 'ListCampaigns',
  components: {
    ErrorBlock,
  },
  data() {
    return {
      list: [],
      loading: true,
      canceling: false,
      error: null,
    };
  },
  mounted() {
    this.getCampaigns();
  },
  methods: {
    getCampaigns() {
      request({
        method: 'get',
        url: '/campaign'
      })
          .then(response => {
            this.list = response.payload;
          })
          .catch(error => {
            const errorText = get(error, 'response.data.message', null);
            if (errorText) {
              this.error = errorText;
            } else {
              this.error = error.toString();
            }
          })
          .finally(() => (this.loading = false));
    },
    renderDate(date) {
      return dayjs(date);
    },
    handleCancelClick(id) {
      this.canceling = true;
      this.error = null;
      request({
        method: 'delete',
        url: `/campaign/${id}/cancel`,
      }).then(response => {
        const {success} = response;
        if (success) {
          this.getCampaigns();
        }
      }).catch(error => {
        const errorText = get(error, 'response.data.message', null);
        if (errorText) {
          this.error = errorText;
        } else {
          this.error = error.toString();
        }
      }).finally(() => (this.canceling = false));
    }
  },
}
</script>
