import Vue from 'vue';
import Router from 'vue-router';
import {isAuthenticated} from '@/helpers';
import Home from './views/Home';
import Backoffice from './views/Backoffice';
import ListCampaigns from './views/ListCampaigns';
import NewCampaign from './views/NewCampaign';
import TrafficShip from './views/TrafficShip';
import Tos from './views/Tos';
import CancellationPolicy from './views/CancellationPolicy';

Vue.use(Router);

const routes = [
    { path: '/', component: Home, name: 'Home' },
    {
        path: '/backoffice',
        component: Backoffice,
        children: [
            {
                path: '',
                component: ListCampaigns,
                name: 'List Campaigns'
            },
            {
                path: 'new-campaign',
                component: NewCampaign,
                name: 'New Campaign'
            },
            {
                path: 'trafficship',
                component: TrafficShip,
                name: 'Advertising with TrafficShip'
            },
            {
                path: 'tos',
                component: Tos,
                name: 'Terms of Service'
            },
            {
                path: 'cancellation-policy',
                component: CancellationPolicy,
                name: 'Cancellation Policy'
            },
        ]
    },
    { path: '*', redirect: { name: 'Home' }}
]

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name === 'Home' && isAuthenticated()) {
        next({ path: '/backoffice' });
    } else if (to.path.startsWith('/backoffice') && !isAuthenticated()) {
        next({ name: 'Home' });
    } else {
        document.body.className = to.name ? to.name.split(' ').join('-').toLowerCase() : '';
        next();
    }
});

export default router;
