<template>
  <modal :name="name" transition="pop-out" :focus-trap="true" @before-open="beforeOpen" :class="{'login-modal': ['login-modal', 'register-modal'].includes(name)}">
    <button class="vm--close" @click="$modal.hide(name)" aria-label="Close">Close</button>
    <div v-scroll-lock="true">
      <slot></slot>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'CommonModal',
  props: {
    name: String,
    isLogin: {
      type: Boolean,
      default: true,
    },
    beforeOpen: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style>
.vm--close {
  position: absolute;
  z-index: 3;
  right: 11px;
  top: 16px;
  background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHdpZHRoPSIxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTEuMjQ4NTM5OC0uMjQ5OTk5NTVjLjI1NTkzMDktLjAwMDI0MTIyLjUxMjA0NDMuMDk3MjAyMzMuNzA3NjY1My4yOTI0ODY5Ny4xOTU2MTcuMTk1MjgwNzUuMjkzNTUzMy40NTEyMjM0MS4yOTM3OTQ1LjcwNzIyODk4LjAwMDI0MTEuMjU2MDA2Ni0uMDk3MjA5OC41MTIxMDgzNy0uMjkyNjUzNC43MDc4ODIyOWwtNC41NDMzNDYyIDQuNTQxNDAxMzEgNC41NDIzNTU4IDQuNTQzNTk4N2MuMTYyNzU1Ni4xNjI3NTMuMjU3NjkzOC4zNjc2Mjk4LjI4NDgxODkuNTc5NTI2MmwuMDA4MTM3NS4xMjc2OTk0YzAgLjI1NTk2LS4wOTc2NDk3LjUxMTkyMi0uMjkyOTU2NC43MDcyMjU1LS4xOTUzMDUxLjE5NTMwMjEtLjQ1MTI2ODguMjkyOTUwMi0uNzA3MjMxMS4yOTI5NTAyLS4yNTU5NjIyIDAtLjUxMTkyNi0uMDk3NjQ4MS0uNzA3MjQwNi0uMjkyOTU5N2wtNC41NDI4ODQxLTQuNTQzMDQwMy00LjU0ODMxMzcxIDQuNTQ5NTk1My0uMDk3NjU4NDYuMDg0MTY0N2MtLjE5ODcxMjcxLjE1MDI2MjYtLjQ0MDg3NTMyLjIxNjg5MzMtLjY3NzkyMjYxLjE5OTI5MjQtLjIzMDY3NTA0LS4wMTcxMjc3LS40NTY0Mjc3OS0uMTEzNzk2LS42MzI0ODcyLS4yOTAxNTM2LS4xOTQ5OTIzNi0uMTk1MzIyNi0uMjkyNjE4MDItLjQ1MTMzMzQtLjI5MjYxODAyLS43MDczMjA1IDAtLjI1NTg4NzcuMDk3NTIzMDYtLjUxMTczNjguMjkyNzQ5ODQtLjcwNjk2MDVsNC41NDEyNTAxNi00LjU0MjYxNzgtNC41NDEzODE5OC00LjU0MjU2MTg4Yy0uMTYyNDI3ODctLjE2MjcwMjk5LS4yNTcxMjk1Ny0uMzY3NjA4NzUtLjI4NDExNTEtLjU3OTUzMDQ5bC0uMDA4MDY1NDctLjEyNzcxMzQ4Yy4wMDAxMjA2OS0uMjU2MDg4Ny4wOTc4Mzk4NS0uNTEyMDY3Ni4yOTI4MzE0My0uNzA3MDU2MTQuMTk1MDcxNzItLjE5NTA2ODY2LjQ1MDk5ODI0LS4yOTI2MzMzNS43MDcwMjE2MS0uMjkyNTEyNjQuMjU1ODA5MjYuMDAwMTIwNi41MTE2ODk1NC4wOTc3NjQ1Ni43MDY5MDI0My4yOTI5NzQ0Mmw0LjUyNzgwNzA4IDQuNTI1NDAwMjEgNC41NTY1OTIyLTQuNTI1MDYwMTFjLjE2MjUyNTctLjE2MzA3ODc0LjM2NzMwNjMtLjI1ODMxNjYxLjU3OTIyMjYtLjI4NTY3MjI2eiIgZmlsbD0iIzViNWI1YiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiM1NTUiIHN0cm9rZS13aWR0aD0iLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMSkiLz48L3N2Zz4=') no-repeat center transparent;
  border: 0;
  appearance: none;
  outline: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  user-select: none;
  font-size: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}
div.vm--container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  @media (max-width: 640px) {
    align-items: flex-end;
    padding-bottom: 0;
    padding-top: 0;
  }
}
div.login-modal {
  padding-top: 160px;

  @media (max-width: 640px) {
    padding-bottom: 0;
    padding-top: 0;
  }

  .vm--modal {
    width: 320px !important;

    @media (max-width: 640px) {
      width: 100% !important;
    }
  }
}
div.vm--modal {
  height: auto !important;
  top: auto !important;
  left: auto !important;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(#000, 0.2);
  overflow: auto;

  @media (max-width: 640px) {
    width: 100% !important;
    border-radius: 8px 8px 0 0;
    max-height: 100%;
  }
}
.vm--overlay {
  background: rgba(0, 0, 0, 0.7);
}
.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.2s;
}
.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
</style>
