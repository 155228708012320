<template>
  <div class="form">
    <button class="vm--close" @click="$emit('close')" aria-label="Close">Close</button>
    <h3 class="title">Payment</h3>
    <div v-if="success">
      <div class="p-20 text-gray-900 text-2xl text-center text-green-700" v-if="success">
        <p>Payment processed successfully. Your campaign has been activated.</p>
      </div>
    </div>
    <div v-if="!success">
      <div class="rounded-t-xl overflow-hidden p-10" v-if="loading">
        <div class="flex justify-around">
          <span class="inline-flex rounded-md shadow-sm">
            <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Loading
            </button>
          </span>
        </div>
      </div>
      <form autocomplete="off" @submit="onSubmit" ref="cardForm" v-if="countries" class="relative">
        <div v-if="sending" class="-bottom-0 absolute bg-opacity-50 bg-white bottom-0 flex items-center justify-around left-0 right-0 rounded top-0 z-50">
          <span class="inline-flex rounded-md shadow-sm">
            <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Loading
            </button>
          </span>
        </div>
        <div class="grid grid-cols-6 gap-6 gap-y-3">
          <div class="col-span-6 sm:col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">First Name</span>
            <label aria-label="First Name">
              <span class="error" v-if="errors.firstName">{{errors.firstName}}</span>
              <input
                  class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                  v-model="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  @input="removeError"
              >
            </label>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">Last Name</span>
            <label aria-label="Last Name">
              <span class="error" v-if="errors.lastName">{{errors.lastName}}</span>
              <input
                  class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                  v-model="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  @input="removeError"
              >
            </label>
          </div>
        </div>

        <div class="py-5">
          <div class="border-t border-gray-200"></div>
        </div>

        <div class="grid grid-cols-6 gap-6 mb-3">
          <div class="col-span-6 sm:col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">Country</span>
            <multiselect
                v-model="selectedCountry"
                :options="countries"
                placeholder="Select one"
                :custom-label="nameWithLang"
            >
            </multiselect>
          </div>
        </div>

        <div class="grid grid-cols-6 gap-6 mb-3">
          <div class="col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">City</span>
            <label aria-label="City">
              <span class="error" v-if="errors.city">{{errors.city}}</span>
              <input
                  class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                  v-model="city"
                  name="city"
                  type="text"
                  placeholder="City"
                  @input="removeError"
              >
            </label>
          </div>
          <div class="col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">Postal Code</span>
            <label aria-label="Postal Code">
              <span class="error" v-if="errors.postalCode">{{errors.postalCode}}</span>
              <input
                  class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                  v-model="postalCode"
                  name="postalCode"
                  type="text"
                  placeholder="Postal Code"
                  @input="removeError"
              >
            </label>
          </div>
        </div>

        <div class="mb-3">
          <span class="block text-sm font-medium text-gray-700 mb-1">Address</span>
          <label aria-label="Address">
            <span class="error" v-if="errors.address">{{errors.address}}</span>
            <input
                class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                v-model="address"
                name="address"
                type="text"
                placeholder="Address"
                @input="removeError"
            >
          </label>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">Email</span>
            <label aria-label="Email">
              <span class="error" v-if="errors.email">{{errors.email}}</span>
              <input
                  class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                  v-model="email"
                  name="email"
                  type="text"
                  placeholder="Email"
                  @input="removeError"
              >
            </label>
          </div>
          <div class="col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">Phone</span>
            <label aria-label="Phone">
              <span class="error" v-if="errors.phone">{{errors.phone}}</span>
              <input
                  class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                  v-model="phone"
                  name="phone"
                  type="tel"
                  placeholder="Phone"
                  @input="removeError"
                  v-mask="{mask: '999999999999999999999999', placeholder: ''}"
              >
            </label>
          </div>
        </div>

        <div class="py-5">
          <div class="border-t border-gray-200"></div>
        </div>

        <div class="mb-3">
          <span class="block text-sm font-medium text-gray-700 mb-1">Card Number</span>
          <label aria-label="Card Number">
            <span class="error" v-if="errors.cardNumber">{{errors.cardNumber}}</span>
            <input
                class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                v-model="cardNumber"
                name="cardNumber"
                type="text"
                placeholder="Card Number"
                data-cardinal-field="AccountNumber"
                @input="removeError"
                v-mask="'9999 9999 9999 9999'"
            >
          </label>
        </div>

        <div class="grid grid-cols-6 gap-6 mb-3">
          <div class="col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">Expiry</span>
            <label aria-label="Expiry">
              <span class="error" v-if="errors.expiry">{{errors.expiry}}</span>
              <input
                  class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                  v-model="expiry"
                  name="expiry"
                  type="tel"
                  placeholder="MM / YY"
                  @input="removeError"
                  v-mask="'99 / 99'"
              >
            </label>
          </div>
          <div class="col-span-3">
            <span class="block text-sm font-medium text-gray-700 mb-1">CVC</span>
            <label aria-label="CVC">
              <span class="error" v-if="errors.cvc">{{errors.cvc}}</span>
              <input
                  class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue pl-3 py-2 rounded-md sm:leading-5 sm:text-sm text-left transition w-full"
                  v-model="cvc"
                  name="cvc"
                  type="tel"
                  placeholder="CVC"
                  @input="removeError"
                  v-mask="'999'"
              >
            </label>
          </div>
        </div>
        <span class="error static" v-if="errors.dfReferenceId">dfReferenceId is missing. Please, try again or contact support</span>
        <p v-if="this.error" class="error static">{{this.error.toString()}}</p>

        <div class="my-6 flex">
          <label aria-label="Expiry" class="flex items-center">
            <input
                class="bg-white border border-gray-300 duration-150 ease-in-out focus:border-blue-300 focus:outline-none focus:shadow-outline-blue p-2 rounded-sm sm:leading-5 sm:text-sm text-left transition"
                v-model="agree"
                name="agree"
                type="checkbox"
                @change="removeError"
            >
            <span class="block text-sm font-medium text-gray-700 ml-2">I agree to the <router-link :to="{ name: 'Terms of Service' }" class="text-blue-800 underline" target="_blank" rel="noreferrer noopener">Terms & Conditions</router-link></span>
          </label>
        </div>
        <div class="submit">
          <Button type="primary" :text="`Pay - ${(amount).toLocaleString('en-GB', {style: 'currency', currency: 'EUR'})}`" :disabled="loading || blocked || !agree" />
        </div>
        <p class="mt-5 text-center text-sm">Alternate payment methods such as wire transfer available on demand, please contact us at
          <a href="mailto:sales@trafficship.com" class="text-blue-800 underline" target="_blank" rel="noreferrer noopener">sales@trafficship.com</a></p>
      </form>
    </div>
  </div>
</template>
<script>

import get from 'lodash.get';
import request from "@/api/request";
import Button from "@/components/common/Button";
import Multiselect from 'vue-multiselect';

const COMMON_ERROR = 'There was an error processing your data. Please check all the details are correct and try again';

export default {
  name: 'PaymentModal',
  props: {
    campaignId: Number,
    amount: Number,
    success: Boolean,
    onSuccess: Function,
  },
  components: {
    Button,
    Multiselect,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      city: null,
      address: null,
      email: null,
      postalCode: null,
      phone: null,
      cardNumber: null,
      expiry: null,
      cvc: null,
      agree: false,
      errors: {},
      loading: true,
      blocked: false,
      sending: false,
      error: null,
      countries: null,
      selectedCountry: null,
      dfReferenceId: null,
      jwt: null,
      threeDSecure: null,
      lookupResponse: null,
    };
  },
  mounted() {
    document.body.classList.add('modal-open');
    request({
      method: 'get',
      url: `/payment?campaign_id=${this.campaignId}`,
    })
        .then(response => {
          if (response.payload) {
            const {songbird_url, token, countries} = response.payload;
            this.countries = Object.values(countries);
            this.selectedCountry = Object.values(countries)[0];
            if (document.getElementById('script-url')) {
              setTimeout(() => {
                this.initCardinal(token);
              }, 200);
            } else {
              const songbirdScript = document.createElement('script');
              songbirdScript.setAttribute('src', songbird_url);
              songbirdScript.setAttribute('id', 'script-url');
              songbirdScript.onload = () => {
                this.initCardinal(token);
              };
              document.head.appendChild(songbirdScript);
            }
          }
        })
        .catch(error => {
          this.error = error.toString();
        })
        .finally(() => (this.loading = false));
  },
  methods: {
    nameWithLang ({ name }) {
      return name;
    },
    initCardinal: function (token) {
      const cardForm = this.$refs.cardForm;
      cardForm.addEventListener('payment.auth.ready', this.sendPayment);
      window.Cardinal.configure({
      });


      window.Cardinal.on('payments.setupComplete', (data) => {
        this.dfReferenceId = data.sessionId;
      });

      window.Cardinal.on('payments.validated', (data, jwt) => {
        switch(data.ActionCode){
          case 'SUCCESS':
            this.jwt = jwt;
            this.threeDSecure = JSON.stringify(data);
            var event = new Event('payment.auth.ready');
            cardForm.dispatchEvent(event);
            break;

          case 'NOACTION':
          case 'FAILURE':
          case 'ERROR':
            this.error = COMMON_ERROR;
            this.blocked = true;
            break;
        }
      });
      window.Cardinal.setup('init', {
        jwt: token,
      });
    },
    onCountrySelect: function (value) {
      this.selectedCountry = value;
    },
    removeError: function (e) {
      this.blocked = false;
      if (this.errors[e.target.name]) {
        delete this.errors[e.target.name];
      }
    },
    validateForm(data) {
      this.errors = {};
      this.error = null;
      Object.keys(data).forEach(key => {
        if (key === 'expiry') {
          const now = new Date();
          now.setDate(1);
          now.setHours(0, 0, 0, 0);
          let expiryDate = 0;
          let isValidDate = true;
          const expiry = data[key] && data[key].split(' / ');
          if (expiry && expiry.length > 1) {
            const expiryMonth = expiry[0];
            const expiryYear = `20${expiry[1]}`;
            expiryDate = new Date(expiryYear, expiryMonth - 1);
            isValidDate = expiryDate instanceof Date && !isNaN(expiryDate) && parseInt(expiryMonth) <= 12;3
          }
          if (!isValidDate || (now > expiryDate)) {
            this.errors[key] = 'Expiry date must be a date in the future';
          }
        }
        if (key === 'cvc') {
          const isCVCValid = /^[0-9]{3}$/.test(data[key]);
          if (!isCVCValid) {
            this.errors[key] = 'CVC must have 3 characters';
          }
        }
        if (!data[key]) {
          this.errors[key] = 'This value should not be blank';
        }
      });
    },
    onSubmit: function (e) {
      e.preventDefault();
      const {
        firstName,
        lastName,
        city,
        address,
        email,
        postalCode,
        phone,
        cardNumber,
        expiry,
        amount,
        cvc,
        dfReferenceId,
      } = this;
      const data = {
        firstName,
        lastName,
        countryIso: this.selectedCountry.iso_3166_1_alpha2,
        city,
        address,
        email,
        postalCode,
        phone,
        cardNumber: cardNumber && cardNumber.replace(/ /g, ''),
        expiry,
        dfReferenceId,
        orderNumber: this.campaignId,
        amount,
        cvc,
      };
      this.validateForm(data);
      if (Object.keys(this.errors).length) return false;
      this.sending = true;
      request({
        url: '/payment/auth',
        method: 'post',
        data,
      })
        .then(response => {
          const {payload} = response;
          if (payload && payload.lookupData) {
            this.lookupResponse = JSON.stringify(payload.lookupData);
            if (payload.lookupData.Enrolled === 'Y' &&
                payload.lookupData.ACSUrl && payload.lookupData.ACSUrl.length &&
                payload.lookupData.Payload && payload.lookupData.Payload.length) {
              window.Cardinal.continue('cca', {
                AcsUrl: payload.lookupData.ACSUrl,
                Payload: payload.lookupData.Payload
              }, {
                OrderDetails: {
                  TransactionId: payload.lookupData.TransactionId
                },
              });

              return;
            }

            if (!(
                payload.lookupData.PAResStatus
                && (
                    (payload.lookupData.PAResStatus === 'N' && !(payload.lookupData.ThreeDSVersion === '2.1.0'|| payload.lookupData.ThreeDSVersion === '2.2.0'))
                    || payload.lookupData.PAResStatus === 'R'
                )
            )) {
              var event = new CustomEvent('payment.auth.ready');
              this.$refs.cardForm.dispatchEvent(event);

              return;
            }

            this.sending = false;
            this.error = COMMON_ERROR;
          }
        })
        .catch(error => {
          this.sending = false;
          const errors = get(error, 'response.data.errors', null);
          if (errors) {
            errors.forEach((e) => {
              this.$set(this.errors, e.field.replace(/[[\]]/g,''), e.error)
            });
          } else {
            this.error = COMMON_ERROR;
          }
        });
    },
    sendPayment() {
      const {
        firstName,
        lastName,
        city,
        address,
        email,
        postalCode,
        phone,
        cardNumber,
        expiry,
        amount,
        cvc,
        dfReferenceId,
        jwt,
        threeDSecure,
        lookupResponse,
      } = this;
      const data = {
        firstName,
        lastName,
        countryIso: this.selectedCountry.iso_3166_1_alpha2,
        city,
        address,
        email,
        postalCode,
        phone,
        cardNumber: cardNumber && cardNumber.replace(/ /g, ''),
        expiry,
        dfReferenceId,
        orderNumber: this.campaignId,
        amount,
        cvc,
        jwt,
        threeDSecure,
        lookupResponse,
      };
      this.sending = true;
      request({
        url: '/payment',
        method: 'post',
        data,
      })
          .then(response => {
            const {payload} = response;
            if (payload) {
              this.onSuccess();
              this.success = true;
            }
          })
          .catch(() => {
            this.error = COMMON_ERROR;
          })
          .finally(() => (this.sending = false));
    },
  }
}
</script>
<style scoped>
  .form {
    padding: 22px;
  }

  .title {
    font-size: 16px;
    color: #1d1d1d;
    font-weight: 700;
    margin: 0 0 23px;
  }

  label {
    position: relative;
    display: block;

    &.flex {
      display: flex;
    }
  }

  .error {
    background: #ff6a58;
    position: absolute;
    right: 8px;
    top: 0;
    transform: translate(0px, -50%);
    height: 14px;
    line-height: 14px;
    border-radius: 4px;
    font-size: 9px;
    font-weight: 700;
    padding: 0 8px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    + .input {
      border-color: rgba(#ff6a58, 0.5);
    }

    &.static {
      position: static;
      transform: none;
      margin: 0;
      text-align: center;
      height: auto;
      line-height: 1.4;
      padding: 4px 8px;
      max-width: 100%;
    }
  }


  .submit {
    margin-top: 16px;
    padding: 0 8px;
    position: relative;
  }

  .btn.primary {
    width: 100%;
    position: relative;
    box-shadow: 0 22px 10px -20px rgba(#0064ff, 0.75);
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .multiselect__input:focus, .multiselect__single:focus {
    box-shadow: none;
  }
  .multiselect__option--highlight, .multiselect__option--selected, .multiselect__option--selected.multiselect__option--highlight {
    background: #f3f3f3;
    color: #35495e;

    &:after {
      display: none;
    }
  }
</style>
