<template>
  <div class="zone">
    <p>{{name}}</p>
    <p class="platform">
      <span v-if="banners && banners.mobile || text">Mobile</span>
      <span v-if="banners && banners.desktop || text">Desktop</span>
    </p>
    <p class="size">
      <span v-if="banners && banners.mobile">{{banners.mobile}}</span>
      <span v-if="banners && banners.desktop">{{banners.desktop}}</span>
      <span v-if="text">Text Link</span>
    </p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Zone',
  props: {
    name: String,
    img: String,
    text: Boolean,
    banners: Object,
  },
}
</script>

<style scoped>
  .zone {
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(#000, 0.1);
    font-size: 14px;
    color: #001e4d;
    text-align: center;
    margin: 0 auto;
    max-width: 320px;
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    p {
      padding: 12px 0;
      border-bottom: 1px solid rgba(#001e4d, 0.1);

      display: flex;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        padding-top: 0;
        font-weight: 700;
      }

      &:last-of-type {
        margin: 0 0 16px;
      }
    }
  }

  .platform {
    span {
      font-weight: 500;
      display: flex;
      align-items: center;
      margin: 0 12px;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHdpZHRoPSIxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtOCAwYy00LjQxODQ2MTU0IDAtOCAzLjU4MTUzODQ2LTggOCAwIDQuNDE4NDYxNSAzLjU4MTUzODQ2IDggOCA4IDQuNDE4NDYxNSAwIDgtMy41ODE1Mzg1IDgtOC0uMDA0OTIzMS00LjQxNi0zLjU4NC03Ljk5NTA3NjkyLTgtOHptNC4wMjgzMDc3IDYuNDA2MTUzODUtMy41NjkyMzA3OCA0Ljc2Nzk5OTk1Yy0uMzIxMjMwNzcuNDMzMjMwOC0uODE2LjcwMjc2OTMtMS4zNTM4NDYxNS43Mzg0NjE2LS4wNDMwNzY5MiAwLS4wODczODQ2MiAwLS4xMzA0NjE1NCAwLS40ODk4NDYxNSAwLS45Ni0uMTk1NjkyMy0xLjMwNTg0NjE1LS41NDI3NjkybC0xLjg0NjE1Mzg1LTEuODQ2MTUzODljLS4zNjY3NjkyMy0uMzU0NDYxNTQtLjM3NjYxNTM4LS45MzkwNzY5My0uMDIyMTUzODUtMS4zMDU4NDYxNi4zNTQ0NjE1NC0uMzY2NzY5MjMuOTM5MDc2OTMtLjM3NjYxNTM4IDEuMzA1ODQ2MTYtLjAyMjE1Mzg0LjAwNzM4NDYxLjAwNzM4NDYxLjAxNDc2OTIzLjAxNDc2OTIzLjAyMjE1Mzg0LjAyMjE1Mzg0bDEuNiAxLjZjLjEyMDYxNTM5LjEyMDYxNTM5LjMxNTA3NjkzLjEyMDYxNTM5LjQzNTY5MjMxIDAgLjAwOTg0NjE2LS4wMDk4NDYxNS4wMTk2OTIzMS0uMDIwOTIzMDcuMDI4MzA3NjktLjAzMzIzMDc3bDMuMzYyNDYxNTItNC40ODM2OTIzYy4zMDUyMzA4LS40MDg2MTUzOS44ODM2OTIzLS40OTEwNzY5MyAxLjI5MjMwNzctLjE4NTg0NjE2cy40OTEwNzY5Ljg4MzY5MjMxLjE4NTg0NjIgMS4yOTIzMDc3YzAgMCAwIC4wMDEyMzA3Ni0uMDAxMjMwOC4wMDEyMzA3NnoiIGZpbGw9IiMwMGNlYWUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==") no-repeat center;
        background-size: cover;
        margin-right: 8px;
      }
    }
  }

  .size {
    span {
      margin: 0 4px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #fff;
      padding: 0 16px;
      font-weight: 600;
      background: #001e4d;
      border-radius: 99px;
    }
  }

  img {
    margin-left: auto;
    margin-right: auto;
  }
</style>
