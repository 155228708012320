import Vue from 'vue'
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        isOutdated: false,
    },
    mutations: {
        setIsOutdated (state, isOutdated) {
            state.isOutdated = isOutdated;
        }
    },
    actions: {
        setIsOutdated({ commit }, payload) {
            commit('setIsOutdated', payload);
        }
    }
});

export default store;
