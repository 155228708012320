<template>
  <div>
    <div class="mt-10 sm:mt-0">
      <ErrorBlock v-if="serverErrors && serverErrors.length">
        <span>
          <span class="block" v-for="error in serverErrors" :key="error">{{error}}</span>
        </span>
      </ErrorBlock>
      <div class="rounded-t-xl overflow-hidden p-10" v-if="loading">
        <div class="flex justify-around">
          <span class="inline-flex rounded-md shadow-sm">
            <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Loading
            </button>
          </span>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2" v-if="zones">
        <form @submit="onSubmit">
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <div class="mb-6">
                    <Select :disabled="disabled" :data="zones" v-model="selectedZone" v-on:valueSelect="onValueSelect" displayAs="${value.website} - ${value.price_per_click}€ cpc - ${value.name}">
                      <template v-slot:SelectLabel>Zone</template>
                    </Select>
                  </div>
                  <div class="mb-6">
                    <label class="block text-sm font-medium text-gray-700 mb-2">Amount of clicks: {{clicks}}</label>
                    <vue-slider :disabled="disabled" v-model="clicks" v-bind="{min: selectedZone.min_clicks, max: selectedZone.max_clicks, interval: selectedZone.clicks_step}" :key="selectedZone.slug" />
                  </div>
                  <div class="mb-6" v-if="selectedZone.type === 'image'" :key="componentKey">
                    <p class="mt-3 mb-3" v-if="selectedZone.banner.mobile"><strong>Banner size:</strong> {{selectedZone.banner.mobile}}</p>
                    <banner-uploader
                        v-if="selectedZone.banner.mobile"
                        :width="selectedZone.banner.mobile.split('x')[0]"
                        :height="selectedZone.banner.mobile.split('x')[1]"
                        :zoneId="selectedZone.id"
                        :setImageId="setImageIdMobile"
                        type="mobile"
                        :disabled="disabled"
                    />
                    <span class="text-red-600 text-sm" v-if="errors.imageIdMobile">{{errors.imageIdMobile}}</span>
                    <p class="mt-3 mb-3"><strong>Banner size:</strong> {{selectedZone.banner.desktop}}</p>
                    <banner-uploader
                        v-if="selectedZone.banner.desktop"
                        :width="selectedZone.banner.desktop.split('x')[0]"
                        :height="selectedZone.banner.desktop.split('x')[1]"
                        :zoneId="selectedZone.id"
                        :setImageId="setImageIdDesktop"
                        type="desktop"
                        :disabled="disabled"
                    />
                    <span class="text-red-600 text-sm" v-if="errors.imageIdDesktop">{{errors.imageIdDesktop}}</span>
                  </div>
                  <div class="mb-6" v-if="selectedZone.type === 'text'">
                    <label for="text" class="block text-sm font-medium text-gray-700">Text</label>
                    <input
                        @change="setText"
                        v-model="text"
                        type="text"
                        name="text"
                        id="text"
                        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        maxlength="12"
                        :disabled="disabled"
                    >
                    <span class="text-red-600 text-sm" v-if="errors.text">{{errors.text}}</span>
                  </div>
                  <div class="mb-6">
                    <label for="link" class="block text-sm font-medium text-gray-700 mb-2">Link</label>
                    <input
                        @change="setLink"
                        v-model="link"
                        type="text"
                        name="link"
                        id="link"
                        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        :disabled="disabled"
                    >
                    <span class="text-red-600 text-sm" v-if="errors.link">{{errors.link}}</span>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3 text-center lg:text-right pt-3 lg:pr-3" v-if="selectedZone && selectedZone.banner">
                  <Zone :name="selectedZone.name.toUpperCase()" :banners="selectedZone.banner" :text="selectedZone.banner.text">
<!--                    <img :src="selectedZone.banner.preview" alt="" width="300">-->
                  </Zone>
                </div>
              </div>
              <p class="flex-grow mt-8 text-sm">
                Once your campaign credits are low, your sales manager will send you an email. In addition, you will receive weekly updates on how your active campaigns are performing.
              </p>
            </div>
            <div class="px-4 py-3 bg-gray-50 sm:px-6 flex items-center">
              <p class="flex-grow pr-3 text-sm text-2xl">
                Total: {{(clicks * selectedZone.price_per_click).toLocaleString('en-GB', {style: 'currency', currency: 'EUR'})}}
              </p>
              <button :disabled="sending" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Save
              </button>
              <button v-if="disabled" @click="resetForm" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 ml-3">
                Reset
              </button>
            </div>
          </div>
        </form>
        <p class="flex-grow my-4 text-center text-sm">Prices listed are in EUR. All charges will read TrafficShip on your statement. Need help with payments?
          <a href="mailto:sales@trafficship.com" class="text-blue-700" target="_blank" rel="noopener noreferrer">Contact Us</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import Select from '@/components/common/Select';
import ErrorBlock from '@/components/common/ErrorBlock';
import Zone from '@/components/common/Zone';
import BannerUploader from '@/components/BannerUploader';
import PaymentModal from '@/components/PaymentModal';
import request from "@/api/request";
import {validURL} from "@/helpers";
import get from "lodash.get";
// import rcBanner from "../assets/right-column-banner.png";
// import faBanner from "../assets/fa-banner.png";
// import footerBanner from "../assets/footer-banner.png";
// import textBanner from "../assets/text-link-banner.png";

const BANNERS = {
  'right_column': {
    desktop: '285x118',
    // preview: rcBanner,
  },
  'featured_ads': {
    mobile: '288x286',
    // preview: faBanner,
  },
  'footer': {
    desktop: '728x180',
    mobile: '314x300',
    // preview: footerBanner,
  },
  'sponsor_links': {
    // preview: textBanner,
    text: true,
  }
};

export default {
  name: 'NewCampaign',
  components: {
    VueSlider,
    Select,
    BannerUploader,
    ErrorBlock,
    Zone,
  },
  data() {
    return {
      clicks: 24000,
      selectedZone: null,
      zones: null,
      errors: {},
      serverErrors: [],
      loading: true,
      sending: false,
      error: null,
      link: null,
      text: null,
      imageIdDesktop: null,
      imageIdMobile: null,
      componentKey: 0,
      campaignId: null,
      success: false,
      disabled: false,
    };
  },
  mounted() {
    request({
      method: 'get',
      url: '/zone'
    })
        .then(response => {
          this.zones = response.payload.zones.map(z => ({...z, banner: BANNERS[z.slug]}));
          const zone = response.payload.zones[0];
          this.selectedZone = {...zone, banner: BANNERS[zone.slug]};
        })
        .catch(error => {
          const errors = get(error, 'response.data.errors', null);
          if (errors) {
            this.serverErrors = errors.map(e => e.error);
          } else {
            this.serverErrors = [error.toString()];
          }
        })
        .finally(() => (this.loading = false));
  },
  methods: {
    resetForm() {
      this.disabled = false;
      this.errors = {};
      this.text = null;
      this.link = null;
      this.campaignId = null;
      this.removeBanner();
    },
    setSuccess() {
      console.warn('success');
      this.success = true;
    },
    setText(e) {
      this.errors = {};
      this.text = e.target.value.trim();
    },
    setLink(e) {
      this.errors = {};
      this.link = e.target.value.trim().toLowerCase();
    },
    setImageIdDesktop(imageId) {
      this.errors = {};
      this.imageIdDesktop = imageId;
    },
    setImageIdMobile(imageId) {
      this.errors = {};
      this.imageIdMobile = imageId;
    },
    removeBanner() {
      this.imageIdDesktop = null;
      this.imageIdMobile = null;
      this.componentKey += 1;
    },
    onValueSelect(value) {
      this.errors = {};
      this.text = null;
      this.campaignId = null;
      this.removeBanner();
      this.selectedZone = value;
    },
    validateForm() {
      this.errors = {};
      if (this.selectedZone.type === 'text' && !this.text) {
        this.errors.text = 'Text is required';
      }
      if (!validURL(this.link)) {
        this.errors.link = 'Link is required';
      }
      if (this.selectedZone.type === 'image' && this.selectedZone.banner.desktop && !this.imageIdDesktop) {
          this.errors.imageIdDesktop = 'Desktop banner is required';
      }
      if (this.selectedZone.type === 'image' && this.selectedZone.banner.mobile && !this.imageIdMobile) {
        this.errors.imageIdMobile = 'Mobile banner is required';
      }
      return true;
    },
    showPaymentModal() {
      this.disabled = true;
      this.$modal.show(PaymentModal, {
        campaignId: this.campaignId,
        amount: this.clicks * this.selectedZone.price_per_click,
        onSuccess: this.setSuccess,
        success: this.success,
      }, {}, { 'closed': () => {
          window.Cardinal.off('payments.validated');
          window.Cardinal.off('payments.setupComplete');
          if (this.success) {
            this.$router.push({name: 'List Campaigns'});
          }

      }});
    },
    onSubmit(e) {
      e.preventDefault();
      const savedData = [this.link, this.selectedZone.id, this.text, this.imageIdDesktop, this.imageIdMobile, this.clicks].join('');
      if (this.campaignId && this.savedData === savedData) {
        return this.showPaymentModal();
      }
      this.savedData = savedData;
      this.validateForm();
      this.serverErrors = [];
      if (Object.keys(this.errors).length) return false;
      const data = {
        zone_id: this.selectedZone.id,
        clicks: this.clicks,
        variant: {}
      }
      if (this.selectedZone.type === 'text') {
        data.variant = {
          desktop: {
            text: this.text,
            url: this.link
          },
          mobile: {
            text: this.text,
            url: this.link
          }
        }
        data.variant.desktop.text = this.text;
        data.variant.desktop.url = this.link;
        data.variant.mobile.text = this.text;
        data.variant.mobile.url = this.link;
      }
      if (get(this.selectedZone, 'banner.desktop', null)) {
        data.variant.desktop = {
          url: this.link,
          image_id: this.imageIdDesktop,
        };
      }
      if (get(this.selectedZone, 'banner.mobile', null)) {
        data.variant.mobile = {
          url: this.link,
          image_id: this.imageIdMobile,
        };
      }
      this.sending = true;
      request({
        method: 'post',
        url: '/campaign',
        data,
      })
        .then(response => {
          const {success, payload} = response;
          if (success) {
            this.campaignId = payload.campaign_id;
            this.showPaymentModal();
          }
        })
        .catch(error => {
          const errors = get(error, 'response.data.errors', null);
          if (errors) {
            this.serverErrors = errors.map(e => e.error);
          } else {
            this.serverErrors = [error.toString()];
          }
        })
        .finally(() => (this.sending = false));
    },
  },
}
</script>
