import axios from 'axios';
import get from 'lodash.get';
import Router from '@/router';
import store from '@/store';
import {TOKEN} from "@/helpers";

axios.defaults.baseURL= process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
    async config => {
        const token = await localStorage.getItem(TOKEN)
        if (token) {
            config.headers['X-Auth-Token'] = token;
        }
        config.headers['X-FE-VERSION-HASH'] = process.env.VUE_APP_COMMIT_HASH;
        return config
    },
    error => {
        return Promise.reject(error)
    }
);

const request = function(options) {
    const handleSuccess = (response) => {
        return response.data;
    }

    const handleError = (error) => {
        const isOutdated = error.response.status === 418 && get(error, 'response.data.payload.message', 'Please reload the page');
        store.dispatch('setIsOutdated', isOutdated);
        switch (error.response.status) {
            case 418:
                return false;
            case 401:
                localStorage.removeItem(TOKEN);
                Router.push({name: 'Home'});
                break;
        }
        return Promise.reject(error)
    }

    return axios(options)
        .then(handleSuccess)
        .catch(handleError);
}

export default request;
