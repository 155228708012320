import 'normalize.css';
import Vue from 'vue';
import vmodal from 'vue-js-modal';
import VScrollLock from 'v-scroll-lock';
import App from './App.vue';
import router from '@/router';
import store from '@/store';

const VueInputMask = require('vue-inputmask').default;
Vue.use(VueInputMask);
Vue.use(vmodal);
Vue.use(VScrollLock);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
