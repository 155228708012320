<template>
  <div class="text-lg text-gray-600 p-4 md:p-8 bg-white rounded-lg">
    <p>
      All purchases made on https://trafficship.com are final. We may, however, grant refunds to resolve customer service issues on a case-by-case basis. We will do our very best to ensure the best customer support in resolving any payment disputes.
    </p>
    <p>
      Please contact us at <a href="mailto:sales@trafficship.com" target="_blank" rel="noopener noreferrer" class="underline">sales@trafficship.com</a> for any refund inquiries - available 24 hours a day, 7 days a week.
    </p>
  </div>
</template>

<script>
export default {
  name: 'CancellationPolicy'
}
</script>

<style scoped>
h2 {
  margin: 0 0 16px;
  font-weight: 700;
  font-size: 18px;
}

p {
  margin: 0 0 8px;
}

.item {
  margin: 0 0 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #eee;

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }

  h2, p:first-of-type {
    text-indent: 32px;
  }
}

a {
  text-decoration: underline;
  color: #3a86f9;
}

b {
  font-weight: 700;
}
</style>
