<template>
  <div>
    <nav class="bg-dark-blue bg-opacity-100">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col md:flex-row justify-between md:h-16">
          <div class="flex flex-col md:flex-row md:items-center">
            <router-link
                custom
                :to="{ name: 'List Campaigns' }"
                class="flex-shrink-0 my-5 md:my-auto"
                exact
                v-slot="{ href, route, navigate }"
            >
              <a
                  :href="href"
                  @click="navigate"
              ><img class="h-8 w-8" src="../assets/rocket.svg" alt="Workflow"></a>
            </router-link>
            <div class="flex-shrink-0">

            </div>
            <div class="md:block">
              <div class="md:ml-10 flex flex-col md:flex-row md:items-baseline md:space-x-4">
                <router-link
                    custom
                    :to="{ name: 'List Campaigns' }"
                    class="text-white px-4 py-2 rounded-md text-sm font-medium"
                    exact
                    v-slot="{ href, route, navigate, isExactActive }"
                >
                  <a
                      :class="[isExactActive && 'bg-white bg-opacity-10 font-bold']"
                      :href="href"
                      @click="navigate"
                  >{{ route.name }}</a>
                </router-link>

                <router-link
                    custom
                    :to="{ name: 'New Campaign' }"
                    class="text-white px-4 py-2 rounded-md text-sm font-medium"
                    exact
                    v-slot="{ href, route, navigate, isExactActive }"
                >
                  <a
                      :class="[isExactActive && 'bg-white bg-opacity-10 font-bold']"
                      :href="href"
                      @click="navigate"
                  >{{ route.name }}</a>
                </router-link>

                <router-link
                    custom
                    :to="{ name: 'Advertising with TrafficShip' }"
                    class="text-white px-4 py-2 rounded-md text-sm font-medium"
                    exact
                    v-slot="{ href, route, navigate, isExactActive }"
                >
                  <a
                      :class="[isExactActive && 'bg-white bg-opacity-10 font-bold']"
                      :href="href"
                      @click="navigate"
                  >{{ route.name }}</a>
                </router-link>
              </div>
            </div>
          </div>
          <div class="my-5 md:my-auto">
            <div class="md:ml-4 flex items-center md:ml-6 w-full">
              <button
                  @click="onLogout"
                  class="bg-white font-medium px-8 py-2 rounded-md text-sm uppercase dark-blue w-full md:w-auto"
                  role="menuitem"
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-extrabold text-gray-900">
          {{$route.name}}
        </h1>
      </div>
    </header>
    <main class="main-viewport">
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <router-view />
      </div>
    </main>
    <footer class="footer bg-white relative">
      <div class="container mx-auto px-6 py-4">
        <div class="mb-4 flex flex-col md:flex-row md:items-baseline space-y-2 md:space-y-0 md:space-x-4 justify-center text-center">
          <router-link
              custom
              :to="{ name: 'List Campaigns' }"
              class="text-sm underline"
              exact
              v-slot="{ href, route, navigate }"
          >
            <a
                :href="href"
                @click="navigate"
            >{{ route.name }}</a>
          </router-link>
          <router-link
              custom
              :to="{ name: 'New Campaign' }"
              class="text-sm underline"
              exact
              v-slot="{ href, route, navigate }"
          >
            <a
                :href="href"
                @click="navigate"
            >{{ route.name }}</a>
          </router-link>
          <router-link
              custom
              :to="{ name: 'Advertising with TrafficShip' }"
              class="text-sm underline"
              exact
              v-slot="{ href, route, navigate }"
          >
            <a
                :href="href"
                @click="navigate"
            >{{ route.name }}</a>
          </router-link>
          <router-link
              custom
              :to="{ name: 'Terms of Service' }"
              class="text-sm underline"
              exact
              v-slot="{ href, route, navigate }"
          >
            <a
                :href="href"
                @click="navigate"
            >{{ route.name }}</a>
          </router-link>
          <router-link
              custom
              :to="{ name: 'Cancellation Policy' }"
              class="text-sm underline"
              exact
              v-slot="{ href, route, navigate }"
          >
            <a
                :href="href"
                @click="navigate"
            >{{ route.name }}</a>
          </router-link>

        </div>
        <p class="max-w-md mx-auto my-4 text-center text-sm">
          If you are not happy with the zone you purchased or want to change zones, please contact us at <a
            href="mailto:sales@trafficship.com" target="_blank" rel="noopener noreferrer" class="underline">sales@trafficship.com</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import {TOKEN} from '@/helpers';
import 'tailwindcss/base.css';
import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css';

export default {
  name: 'Backoffice',
  methods: {
    onLogout: function () {
      localStorage.removeItem(TOKEN);
      this.$router.push({name: 'Home'});
    },
  }
}
</script>

<style>
  .dark-blue {
    color: #001e4d;
  }
  .bg-dark-blue {
    background-color: rgba(0, 30, 77, var(--tw-bg-opacity));
  }
  .main-viewport {
    min-height: calc(100vh - 300px);
  }
</style>
