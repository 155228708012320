<template>
  <div class="mb-6">
    <div class="flex-grow">
      <div class="card-footer text-muted mb-3 mt-3 font-bold" v-html="message" :class="{
        'text-green-800': message === 'Upload completed.',
        'text-red-600': message === 'Oops! Something went wrong.',
      }"></div>
    </div>
    <div :style="`width: ${width}px; height: ${height}px`" class="bg-gray-100 flex items-center justify-center relative cursor-pointer max-w-full md:max-w-none" :id="`pick-avatar-${type}`">
      <span v-if="!url" class="absolute font-bold text-4xl text-gray-300">{{`${width}X${height}`}}</span>
      <img :src="url" class="object-contain max-w-none w-full h-full" v-if="url" />
    </div>
    <avatar-cropper
        v-if="!disabled"
        @uploading="handleUploading"
        @uploaded="handleUploaded"
        @completed="handleCompleted"
        @error="handlerError"
        :trigger="`#pick-avatar-${type}`"
        upload-form-name="image"
        :upload-url="endpoint"
        :upload-form-data="{zone_id: zoneId, type: type}"
        :labels="{submit: 'Submit', cancel: 'Cancel'}"
        mimes="image/png, image/jpeg"
        output-mime="image/jpeg"
        :cropper-options="{
          aspectRatio: width / height,
          autoCropArea: 1,
          movable: false,
          scalable: false,
          zoomable: false,
          zoomOnTouch: false,
          zoomOnWheel: false,
          cropBoxResizable: false,
          toggleDragModeOnDblclick: false,
          dragMode: 'none',
          rotatable: true,
          checkOrientation: true,
        }"
        :output-options="{width, height}"
        :id="`input-avatar-${type}`"
        :upload-headers=getAuthHeader()
    />
  </div>
</template>

<script>
import AvatarCropper from 'vue-avatar-cropper';
import {TOKEN} from "@/helpers";

export default {
  props: {
    width: String,
    height: String,
    zoneId: Number,
    type: String,
    setImageId: Function,
    disabled: Boolean,
  },
  components: { AvatarCropper },
  data() {
    return {
      message: '',
      endpoint: `${process.env.VUE_APP_API_URL}/image/upload`,
      url: null,
    };
  },
  methods: {
    handleUploading() {
      this.message = 'Uploading...';
    },
    handleUploaded(response) {
      if (response.success) {
        this.url = response.payload.url;
        this.setImageId(response.payload.image_id);
      }
      if (response.errors) {
        this.message = Object.values(response.errors)[0];
      }
    },
    handleCompleted() {
      this.message = 'Upload completed.';
    },
    handlerError() {
      this.message = 'Oops! Something went wrong.';
    },
    getAuthHeader() {
      return {
        'X-AUTH-TOKEN': window.localStorage.getItem(TOKEN),
        'X-FE-VERSION-HASH': process.env.VUE_APP_COMMIT_HASH,
      };
    }
  }
};
</script>
