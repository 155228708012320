<template>
  <CommonModal name="register-modal">
    <div class="py-20 px-10 text-gray-900 text-2xl text-center" v-if="success">
      <p>{{success}}</p>
    </div>
    <div class="form" v-if="!success">
      <h3 class="title">Register</h3>
      <form autocomplete="off" @submit="onSubmit">
        <label aria-label="Name">
          <span class="error" v-if="errors.name">{{errors.name}}</span>
          <input class="input" v-model="name" name="name" type="text" placeholder="Name" @input="removeError">
        </label>
        <label aria-label="Email">
          <span class="error" v-if="errors.email">{{errors.email}}</span>
          <input class="input" v-model="email" name="email" type="text" placeholder="Email" @input="removeError">
        </label>
        <label aria-label="Tell us about you">
          <span class="error" v-if="errors.comment">{{errors.comment}}</span>
          <textarea class="input" v-model="comment" name="comment" placeholder="Tell us about you" @input="removeError"></textarea>
        </label>
        <p v-if="this.error" class="error static">{{this.error.toString()}}</p>
        <div class="submit">
          <Button type="primary" text="Register" :disabled="disabled || loading" />
        </div>
      </form>
    </div>
  </CommonModal>
</template>
<script>

import CommonModal from "@/components/common/CommonModal";
import Button from "@/components/common/Button";
import {isValidEmail} from "@/helpers";
import request from "@/api/request";
import get from "lodash.get";

export default {
  name: 'RegisterModal',
  components: {
    CommonModal,
    Button,
  },
  data() {
    return {
      name: null,
      email: null,
      comment: null,
      errors: {},
      data: null,
      loading: false,
      error: null,
      success: null,
      disabled: true
    };
  },
  methods: {
    removeError: function (e) {
      this.disabled = Boolean(Object.keys(this.checkForm(false)).length);
      if (this.errors[e.target.name]) {
        delete this.errors[e.target.name];
      }
    },
    checkForm: function (showErrors = true) {
      const isNameValid = this.name && this.name.trim().length >= 2;
      const isCommentValid = this.comment && this.comment.trim().length >= 2;
      const errors = {};

      if (!isNameValid) {
        errors.name = 'Field is required';
      }
      if (!isValidEmail(this.email)) {
        errors.email = 'Invalid Email';
      }
      if (!isCommentValid) {
        errors.comment = 'Field is required';
      }
      if (showErrors) this.errors = errors;
      return errors;
    },
    onSubmit: function (e) {
      e.preventDefault();
      this.error = null;
      this.checkForm();
      if (Object.keys(this.errors).length) return false;
      this.loading = true;
      const formData = new FormData(e.target);
      request({
        url: '/contact',
        method: 'post',
        data: formData,
      })
        .then(response => {
          const {success} = response;
          if (success) {
            this.success = 'Your request was sent! We’ll get in touch by email shortly';
          }
        })
        .catch(error => {
          const errors = get(error, 'response.data.errors', null);
          if (errors) {
            errors.forEach((e) => {
              this.errors[e.field.replace(/[[\]]/g,'')] = e.error;
            });
          } else {
            this.error = [error.toString()];
          }
        })
        .finally(() => (this.loading = false));
    },
  }
}
</script>
<style scoped>
  .form {
    padding: 22px 22px 44px;
  }

  .title {
    font-size: 16px;
    color: #1d1d1d;
    font-weight: 700;
    margin: 0 0 23px;
  }

  label {
    display: block;
    margin: 0 0 20px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .error {
    background: #ff6a58;
    position: absolute;
    right: 8px;
    top: 0;
    transform: translate(0px, -50%);
    height: 14px;
    line-height: 14px;
    border-radius: 4px;
    font-size: 9px;
    font-weight: 700;
    padding: 0 8px;

    + .input {
      border-color: rgba(#ff6a58, 0.5);
    }

    &.static {
      position: static;
      transform: none;
      margin: 0 0 -20px;
      text-align: center;
      height: auto;
      line-height: 1.4;
      padding: 4px 8px;
    }
  }

  .input {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: #fff;
    height: 44px;
    padding: 10px 15px;
    font-size: 14px;
    outline: 0;
    width: 100%;
    color: #1d1d1d;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    &::placeholder {
      color: #9b9b9b;
    }
  }

  textarea.input {
    height: 160px;
  }

  .submit {
    margin-top: 36px;
    padding: 0 8px;
    position: relative;
  }

  .btn.primary {
    width: 100%;
    position: relative;
    box-shadow: 0 22px 10px -20px rgba(#0064ff, 0.75);
  }
</style>
