<template>
  <button class="btn" :class="type" @click="onClick($event)" :disabled="disabled">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    type: String,
    disabled: Boolean,
  },
  methods: {
    onClick: function(e) {
      this.$emit('click', e);
    }
  },
}
</script>

<style scoped>
  button {
    appearance: none;
    background: none;
    border: 0;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    padding: 0 12px;
    width: 224px;
    border-radius: 999px;
    cursor: pointer;
    outline: 0;
    box-shadow: 0 0 0 1px #0064ff;
    transition: all ease 0.2s;

    &:hover {
      background: rgba(#0064ff, 0.3);
    }

    &:active {
      transform: translate3d(0, 1px, 0);
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .primary {
    background: #0064ff;

    &:hover {
      background: #3a86f9;
    }
  }
</style>
